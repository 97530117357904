import React, { useEffect, useRef } from "react";

// Function to get the wishlist from the cookie
function getCookie(name) {
  // Get all the cookies
  var cookies = document.cookie.split(';');

  // Loop through each cookie
  for (var i = 0; i < cookies.length; i++) {
      // Get the cookie name and value
      var cookie = cookies[i].trim().split('=');
      var cookieName = cookie[0];
      var cookieValue = cookie[1];

      // Check if the cookie name matches the requested name
      if (cookieName === name) {
          // Return the cookie value
          return cookieValue;
      }
  }

  // If the cookie was not found, return an empty string
  return '';
}

const Recommendations = ({ template, searchUrl, resultText }) => {
  const recommendationsContainerRef = useRef(null)
  useEffect(() => {
    const container = recommendationsContainerRef.current;
    if (!container) {
      return;
    }
    $(container).find(".image-container.tile-slider").each(function () {
      var $container = $(this);
      if ($container.find("img").length > 1) {
        $container.slick({
          dots: true,
          dotsClass: "slick-dots tile-dots",
          prevArrow:
            '<button type="button" class="slick-prev tile-slick-arrow tile-prev">Previous</button>',
          nextArrow:
            '<button type="button" class="slick-next tile-slick-arrow tile-next">Next</button>',
        });
      } else {
        $container.css("display", "flex");
        $container.find("img").css("align-self", "center");
      }

      var wishlistCookie = getCookie('wishlist');
      var products = $(container).find('.product');

      // Check product in wishlist
      if (products.length) {
        products.each(function() {
            if (wishlistCookie.includes($(this).data('pid'))) {
                // The product is in the wishlist
                $(this).find('.btn-wishlist').addClass('active');
            } else {
                // The product is not in the wishlist
                $(this).find('.btn-wishlist').removeClass('active');
            }
        });
      }
    });
    $(".image-container img").removeClass("d-none");
  }, [template]);

  return (
    <div ref={recommendationsContainerRef}>
      {searchUrl ? (
        <div className="recommendations-container products-main-block search-results">
          <h1>We’ve found {resultText} Perfect Match options for you: </h1>
          <p>
            If these RVs are not located near you, don’t worry, we’re pros at
            making long distance relationships work!
          </p>
          <div className="product-grids-block">
            <div
              className="matching-recommendations row product-grid"
              dangerouslySetInnerHTML={{ __html: template }}
            ></div>
          </div>
          <p>
            None of these are “The One”? See more RVs that match your needs <a href={searchUrl}><u>here</u></a>
          </p>
        </div>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: template }}></div>
      )}
    </div>
  );
};
export default Recommendations;
