import React from "react";
import Quiz from "./components/Quiz";

const questions = [
  {
    "key": "name",
    "mainHeader": "Every adventurer is unique",
    "subHeader": "So are your needs!  We'd like to get to know the person inside the RV.",
    "type": "text",
    "required": true,
    "showSkip": false,
    "showSubmit": true,
    "options": []
  },
  {
    "key": "dealerLocation",
    "mainHeader": "Which location is most convenient?",
    "subHeader": "Please select one.",
    "type": "radio",
    "showSkip": false,
    "showSubmit": true,
    "options": [
      { "text": "Abbotsford", "value": "Abbotsford Fraserway" },
      { "text": "Kelowna", "value": "Kelowna" },
      { "text": "Lake Country", "value": "Winfield" },
      { "text": "Kamloops", "value": "Kamloops" },
      { "text": "Prince George", "value": "Prince George" },
      { "text": "White Horse", "value": "Whitehorse" },
      { "text": "Calgary", "value": "Airdrie" },
      { "text": "Edmonton", "value": "Edmonton" },
      { "text": "Leduc", "value": "Leduc" },
      { "text": "Red Deer", "value": "Red Deer" },
      { "text": "Lacombe", "value": "Lacombe" },
      { "text": "Cookstown", "value": "Toronto" },
      { "text": "Halifax", "value": "Halifax" }
    ]

  },
  {
    "key": "firstRV",
    "mainHeader": "Is this your first RV?",
    "type": "simpleButtons",
    "singleSelect": true,
    "showSkip": false,
    "showSubmit": false,
    "options": [
      { "text": "Yes, this is a new adventure for me!", "value": "true" },
      { "text": "No, I'm already a seasoned RVer", "value": "false" }
    ]
  },
  {
    "key": "rvType",
    "mainHeader": "How will you be using your RV?",
    "subHeader": "Please select all that apply.",
    "type": "tileButtons",
    "showSkip": true,
    "showSubmit": true,
    "options": [
      { "text": "Dry Camping", "supportText": "(no hook-ups)", "image": "Slide_5_1", "value": "dryCamping" },
      { "text": "Camping", "supportText": "(with hookups)", "image": "Slide_5_2", "value": "camping" },
      { "text": "Roadtripping/ Boondocking", "image": "Slide_5_3", "value": "roadTrippingBoondocking"},
      { "text": "Permanent/ Seasonal Living", "image": "Slide_5_4", "value": "permanentSeasonalLiving" }
    ]
  },
  {
    "key": "rvAdventuresKind",
    "mainHeader": "Who will be adventuring with you?",
    "subHeader": "Please select all that apply.",
    "type": "imageText",
    "showSkip": true,
    "showSubmit": true,
    "options": [
      { "text": "Solo", "value": "Solo", "image": "Slide_6_1" },
      { "text": "Young Children", "value": "Young Children", "image": "Slide_6_2" },
      { "text": "Teenagers or Other Adults", "value": "Teenagers", "image": "Slide_6_3" },
      { "text": "Elderly or Disabled Individuals", "value": "Elder", "image": "Slide_6_4" }
    ]
  },
  {
    "key": "totalSleeps",
    "mainHeader": "How many will be sleeping in your RV?",
    "type": "connectedRadio",
    "showSkip": true,
    "showSubmit": true,
    "options": [
      { "text": "1-2", "value": "1-2"},
      { "text": "3-6", "value": "3-7"},
      { "text": "7+", "value": "7-20"}
    ]
  },
  // {
  //   "key": "layoutFeatures",
  //   "mainHeader": "What must your RV have?",
  //   "subHeader": "Please select all that apply.",
  //   "type": "imageText",
  //   "showSkip": true,
  //   "showSubmit": true,
  //   "options": [
  //     { "text": "Bathroom", "value": "Bathroom", "image": "Slide_8_1" },
  //     { "text": "Outdoor Kitchen", "value": "Outdoor Kitchen", "image": "Slide_8_2" },
  //     { "text": "Bunkhouse", "value": "Bunkhouse", "image": "Slide_8_3" }
  //   ]
  // },
  {
    "key": "toyHauler",
    "mainHeader": "Need extra space for toys? (ATVs, motorcycles, jet skis, etc.)",
    "type": "simpleButtons",
    "showSkip": true,
    "showSubmit": false,
    "options": [
      { "text": "Yes", "value": "true" },
      { "text": "No", "value": "false" }
    ]
  },
  {
    "key": "length",
    "mainHeader": "Any length requirements?",
    "subHeader": "We know most standard campsites allow RVs up to 30ft in length.",
    "type": "connectedRadio",
    "showSkip": true,
    "showSubmit": true,
    "options": [
      { "text": "Under 20 ft", "value": "0-240" },
      { "text": "20-30 ft", "value": "240-360" },
      { "text": "Over 30 ft", "value": "360-600" }
    ]
  },
  {
    "key": "productType",
    "mainHeader": "How do you like to move your RV?",
    "type": "tileButtons",
    "singleSelect": true,
    "showSkip": true,
    "showSubmit": false,
    "options": [
      { "text": "Tow it", "supportText": "5th Wheel, travel & tent trailers", "image": "Slide_11_1", "value": "Tow" },
      { "text": "Drive it", "supportText": "Class A, B & C motorhomes", "image": "Slide_11_2", "value": "Drive" },
      { "text": "Mount it", "supportText": "Truck campers", "image": "Slide_11_3", "value": "Mount" }
    ]
  },
  {
    "key": "dryWeight",
    "mainHeader": "What vehicle will tow your RV?",
    "subHeader": "Towing capacity can vary.  Please consult with our sales team for guidance, or your vehicle's owners manual to understand what you can safely tow.​",
    "type": "tileButtons",
    "singleSelect": true,
    "showSkip": true,
    "showSubmit": true,
    "condition": {
      "key": "productType",
      "value": ["Tow"]
    },
    "customInput": {
      "text": "Or, what is the maximum you would like to tow?",
      "placeholder": "(lbs)"
    },
    "options": [
      {
        "text": "Light SUV",
        "supportText": "e.g. Ford Escape, Mazda CX-9",
        "image": "Slide_12_2",
        "value": "0-2000"
      },
      {
        "text": "Mid-Sized Truck",
        "supportText": "e.g. Toyota Tacoma, Ford Ranger",
        "image": "Slide_12_3",
        "value": "0-6500"
      },
      {
        "text": "Half-ton Truck",
        "supportText": "e.g. F-150, GM/RAM 1500, Toyota Tundra",
        "image": "Slide_12_4",
        "value": "0-13000"
      },
      {
        "text": "3/4 + Ton Truck",
        "supportText": "e.g. F-250, F-350, RAM 2500 or 3500",
        "image": "Slide_12_5",
        "value": "0-22000"
      }
    ]
  },
  {
    "key": "motorhomeType",
    "condition": {
      "key": "productType",
      "value": ["Drive"]
    },
    "mainHeader": "Going motorized? What do you prefer?",
    "subHeader": "Please select all that apply",
    "type": "tileButtons",
    "singleSelect": true,
    "showSkip": true,
    "showSubmit": false,
    "options": [
      { "text": "Class A", "supportText": "If you're looking for the biggest & most luxurious", "value": "Class A Motorhomes", "image": "Slide_13_1" },
      { "text": "Class B", "supportText": "AKA Camper Vans, they are the most versatile & easy to drive", "value": "Class B Motorhomes|Class B+ Motorhomes", "image": "Slide_13_2" },
      { "text": "Class C", "supportText": "The best of all worlds, offering luxury and versatility", "value": "Class C Motorhomes", "image": "Slide_13_3" }
    ]
  },
  {
    "key": "finance",
    "mainHeader": "How are you planning to purchase your RV?",
    "subHeader": "Please select one.",
    "type": "tileButtons",
    "singleSelect": true,
    "showSkip": true,
    "showSubmit": false,
    "options": [
      { "text": "Cash", "value": "cash", "image": "Slide_14_1" },
      { "text": "Financing", "value": "financing", "image": "Slide_14_2" }
    ]
  },
  {
    "key": "payment",
    "mainHeader": "What bi-weekly payment works for you?",
    "condition": {
      "key": "finance",
      "value": ["financing"]
    },
    "type": "connectedRadio",
    "showSkip": true,
    "showSubmit": true,
    "options": [
      { "text": "Up to $100", "value": "0-150" },
      { "text": "Up to $200", "value": "0-250" },
      { "text": "Up to $350", "value": "0-400" },
      { "text": "Up to $500", "value": "0-550" },
      { "text": "$500+", "value": "400-2000" }
    ]
  },
  {
    "key": "price",
    "mainHeader": "What budget do you have in mind?",
    "type": "connectedRadio",
    "showSkip": true,
    "showSubmit": true,
    "condition": {
      "key": "finance",
      "value": ["cash"]
    },
    
    "options": [
      { "text": "Under $20K", "value": "0-25000" },
      { "text": "$20k-$40k", "value": "0-45000" },
      { "text": "$40k-$60k", "value": "0-65000" },
      { "text": "$60k-$100k", "value": "0-105000" },
      { "text": "Over $100k", "value": "80000-600000" }
    ]
  },
  {
    "key": "tradeIn",
    "mainHeader": "Got a trade-in?",
    "type": "simpleButtons",
    "singleSelect": true,
    "showSkip": true,
    "showSubmit": false,
    "options": [
      { "text": "Yes", "value": "true" },
      { "text": "No", "value": "false" }
    ]
  },
  {
    "key": "email",
    "mainHeader": "Your email address will unlock your possible matches!",
    "type": "email",
    "showSkip": false,
    "showSubmit": false,
    "options": [
      { "text": "Yes, I would like a sales person to contact me", "value": "contactMe" },
      { "text": "Yes, I would like to receive promotional offers, exclusive discounts and RVing educational content.", "value": "getPromo" }
    ]
  }
];


const App = () => {
  return <Quiz questions={questions}></Quiz>;
};

export default App;
